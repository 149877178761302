.game-box {
    /* width: 550px; */
    margin:0 auto; 
    width: 30%;
    top: 50%;
    height: 100vh;
    background-color: crimson;
    padding: 20px;
    
  }
  
  .reset-btn {
    display: none;
  }
  
  .start-btn {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 40px;
    border-radius: 12px;
    width: 180px;
    height: 35px;
    font-size: 25px;
    font-weight: bold;
    border: 3px solid white;
  }
  
  .start-btn:hover {
    background: rgb(238, 81, 24);
    color: white;
  }
  
  .reward {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 800px;
    height: 500px;
    /* margin-top: -250px;*/
    margin-left: -400px;
    z-index: 100;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
  }
  
  .reward p {
    font-size: 25px;
  }
  
  .reward img {
    width: 225px;
  }
  
  .btn-close {
    margin-top: 50px;
    font-family: inherit;
    font-size: 20px;
    padding: 10px 30px;
    border: 1px solid black;
    background: #fff;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.2s;
    transition-timing-function: linear;
  }
  
  .btn-close:hover {
    background: black;
    color: white;
  }
  
  .mask {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
  }
  