.App {
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.space{
  width: 8px;
  display: flex;
}

.custom-editor{
  height: 100vh;
}

.main-dashboard-email{
  margin-top: 5px;
  background-color: #F2F2F2; 
  height: 100%;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.content-send-email{
  height: 85vh;
  background-color: white;
  margin-top: 15px;
  padding:8px;
}
.main-dashboard-zalo{
  background-color: #F2F2F2;
  height:91vh;
  /* padding: 10px; */
}
.content-send-zalo{
  /* background-color: white; */
  height: 85vh;
  width: auto;
  /* margin-top: 8px; */
  padding: 8px;
  /* padding: 8px; */
}
.container-send{
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10px;
  height: 82vh;
  background-color: white;
}

.quill-content-sendEmail{
  height: 73vh;
}
.cls-grid-list{
  height: 86vh;
  background-color: white;
}
@media only screen and (max-width: 480px) {
  /* CSS ở đây */
  .container-send{
    margin-top: 5px;
    
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  .container-send{
    margin-top: 5px;
    
  }
  /* CSS ở đây */
}

.main-sms-zalo{
  background-color: blanchedalmond;
  width: 100%;
  height: 89vh;
  overflow: auto;
}
.header-template{
  background-color: #072c52;
  height: 50px;
  text-align: end;
}
.button-email-editer{
  height: 40px;
  width: 120px;
  cursor: pointer;
  margin: 5px 10px;
  font-size: 16px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-content {
  overflow: hidden; /* Hide any content that exceeds the card's boundaries */
}
.zoomed-iframe {
  width: 100%;
  height: 300px;
  transform: scale(1.5); /* Zoom the iframe and its content by 1.5 times */
  transform-origin: top left; /* Set the zoom origin to top-left */
}

.container-template {
  height: 600px;
  width: 600px;
  border: 1px solid #ccc;
  overflow: auto;
  padding-top: 10px;
  margin-top: 8px;
}

.div-card-template{
  background-color: white;
  position: relative;
  cursor: pointer;
}
.hidden-button {
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 8px 16px;
  background-color: teal;
  border: 1px solid #333;
  border-radius: 4px;
  display: none;
  top: 30%;
  left: 50%;
  width: 70px;
  cursor: pointer;
}
.div-card-template:hover .hidden-button,
.div-card-template:hover .hidden-button-edit,
.div-card-template:hover .hidden-button-delete
{
  display:block;
}
.hidden-button-edit{
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 8px 16px;
  background-color: green;
  border: 1px solid #333;
  border-radius: 4px;
  display: none;
  top: 50%;
  left: 50%;
  width: 70px;
  cursor: pointer;
}

.hidden-button-delete{
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 8px 16px;
  background-color: rgb(143, 22, 28);
  border: 1px solid #333;
  border-radius: 4px;
  display: none;
  top: 70%;
  left: 50%;
  width: 70px;
  cursor: pointer;
}

.html-preview {
  height: 89vh;
  /* Các kiểu CSS chung cho mọi kích thước màn hình */

  /* Kiểu CSS dành cho màn hình nhỏ (ví dụ: điện thoại di động) */
  @media screen and (max-width: 767px) {
    /* Định nghĩa các kiểu CSS cho màn hình nhỏ */
  }

  /* Kiểu CSS dành cho màn hình trung bình (ví dụ: máy tính bảng) */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    /* Định nghĩa các kiểu CSS cho màn hình trung bình */
  }

  /* Kiểu CSS dành cho màn hình lớn (ví dụ: máy tính để bàn) */
  @media screen and (min-width: 1024px) {
    /* Định nghĩa các kiểu CSS cho màn hình lớn */
  }
} 

#canvas{
  width: 110%;
  margin-left: 40%;
}
#wheel{
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.button-text-color{
  border: gray 1px solid;
  width: 35px;
  cursor: pointer;
  height: 35px;
}
.vongquaymayman{
  background-image: url("./Image/spinner_to-print-512x512.png");
}